<template>
  <u-card
    class="mx-auto mt-16 w-[calc(100%_-_2rem)] md:w-1/2"
    :ui="{ footer: { padding: '!pl-4' } }"
  >
    <template #header>
      <h1 class="text-2xl">Sign in</h1>
    </template>
    <u-form :state="state" class="space-y-4" @submit="throttledSubmit">
      <u-form-group
        name="email"
        label="Email"
        :error="touched.has('email') && left.has('email') && errorState.email"
      >
        <u-input
          v-model="state.email"
          autocomplete="email"
          @focus="onFocus('email')"
          @blur="onBlur('email')"
        />
      </u-form-group>
      <u-form-group
        name="password"
        label="Password"
        :error="
          touched.has('password') && left.has('password') && errorState.password
        "
      >
        <template #hint>
          <u-button
            tabindex="-1"
            variant="link"
            class="p-0 text-sm"
            to="/u/recovery"
            :disabled="isProcessing || isMagicLinkSigningIn"
          >
            Forgot password?
          </u-button>
        </template>
        <u-input
          v-model="state.password"
          type="password"
          autocomplete="current-password"
          @focus="onFocus('password')"
          @blur="onBlur('password')"
        />
      </u-form-group>
      <div class="flex gap-2">
        <u-button
          type="submit"
          :disabled="isProcessing || isMagicLinkSigningIn || !isValid"
          :loading="isProcessing"
        >
          Sign in
        </u-button>
        <u-button
          v-if="
            schema
              .omit({
                password: true,
              })
              .safeParse(state).success
          "
          color="white"
          :disabled="isProcessing || isMagicLinkSigningIn"
          :loading="isMagicLinkSigningIn"
          @click="sendMagicLink"
        >
          Send magic link
        </u-button>
      </div>
    </u-form>
    <template #footer>
      <u-button
        variant="ghost"
        to="/sign-up"
        :disabled="isProcessing || isMagicLinkSigningIn"
      >
        Don't have an account?
      </u-button>
    </template>
  </u-card>
</template>

<script lang="ts" setup>
definePageMeta({
  middleware: ['unauth'],
  layout: 'sign-in',
})

useHead({
  title: 'Sign in',
})

const supabase = useSupabaseClient<AppDatabase>()
const toast = useToast()
const {
  schema,
  state,
  isValid,
  isProcessing,
  errorState,
  touched,
  left,
  onFocus,
  onBlur,
  resetForm,
} = useForm({
  type: 'object',
  properties: {
    email: {
      type: 'string',
      name: 'Email',
      format: 'email',
    },
    password: {
      type: 'string',
      name: 'Password',
      minLength: 8,
      maxLength: 24,
    },
  },
  required: ['email', 'password'],
})
const throttledSubmit = useThrottleFn(submit, 500)
const isMagicLinkSigningIn = ref(false)

async function submit() {
  try {
    isProcessing.value = true

    const { email, password } = state
    const { status_code } = await $fetch('/api/user', {
      params: {
        email,
      },
      ignoreResponseError: true,
    })

    if (status_code === 200) {
      const { error } = await supabase.auth.signInWithPassword({
        email,
        password,
      })

      if (error) {
        throw error
      }

      await navigateTo('/')

      toast.add({
        color: 'green',
        title: 'Signed in!',
      })
    } else if (status_code === 404) {
      isProcessing.value = false

      toast.add({
        color: 'red',
        title: "We couldn't find an account with that email.",
        actions: [
          {
            label: 'Sign up',
            click: () => navigateTo('/sign-up'),
          },
        ],
      })
    } else {
      throw new Error('Something went wrong.')
    }
  } catch (error) {
    isProcessing.value = false

    console.error(error)

    toast.add({
      color: 'red',
      title: 'Could not sign in.',
    })
  } finally {
    resetForm()
  }
}

async function sendMagicLink() {
  try {
    isMagicLinkSigningIn.value = true

    const { email } = state
    const { status_code } = await $fetch('/api/user', {
      params: {
        email,
      },
    })

    if (status_code === 200) {
      const { status_code } = await $fetch('/api/user/magic-link', {
        method: 'POST',
        body: {
          location: window.location.origin,
          email,
        },
      })

      if (status_code !== 200) {
        throw new Error('Something went wrong generating a magic link.')
      }

      toast.add({
        color: 'green',
        title: '🪄 Magic link sent!',
        description: 'Check your email for a link to sign in.',
      })
    } else if (status_code === 404) {
      isMagicLinkSigningIn.value = false

      toast.add({
        color: 'red',
        title: "We couldn't find an account with that email.",
        actions: [
          {
            label: 'Sign up',
            click: () => navigateTo('/sign-up'),
          },
        ],
      })
    } else {
      throw new Error('Something went wrong.')
    }
  } catch (error) {
    isMagicLinkSigningIn.value = false

    console.error(error)

    toast.add({
      color: 'red',
      title: 'Could not send magic link.',
    })
  } finally {
    resetForm()
  }
}

watch(
  state,
  (value) => {
    for (const key in value) {
      touched.value.add(key)
    }
  },
  { deep: true },
)
</script>
